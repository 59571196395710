.Card1 {
  border: 0.5px solid #d9d9d9;
  height: 150px;
  padding: 20px;
  .cardHead {
    font-family: "Inria Serif";
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 18px;
    color: #8c673e;
    margin-bottom: 10px;
  }
  .cardText {
    font-family: "Inria Serif";
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 14px;
    color: #404b3b;
  }

  .cardText2 {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .smallCards {
      display: flex;
      justify-content: flex-start;
      width: 80%;
      .d1,
      .d2 {
        height: 70px;
        border: 1px solid #d9d9d9;
        width: 100%;
        .dDraft,
        .dDraft1 {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "Inria Serif";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          color: #8c673e;
          padding-top: 8px;
        }
        p {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "Inria Serif";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          color: #000;
          margin-top: 4px;
        }
      }
      .d2 {
        margin-left: 5px;
      }
    }
    .Number {
      font-family: "Inria Serif";
      font-style: normal;
      font-weight: 700;
      font-size: 23px;
      line-height: 22px;
      /* identical to box height */

      color: #262d22;
      margin-top: 5px;
    }
    svg {
      width: 40px;
      height: 40px;
    }
  }
}
.Card2 {
  border: 0.5px solid #d9d9d9;
  height: 460px;
  .cardHeader {
    background: #ffffff;
    border-bottom: 0.5px solid #d9d9d9;
    border-radius: 4px;
    height: 60px;
    align-items: center;
    display: flex;
    padding-left: 20px;
    font-family: "Inria Serif";
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 18px;
    /* identical to box height */

    color: #1a1a1a;
  }
  .cardHead {
    font-family: "Inria Serif";
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 18px;
    color: #8c673e;
    margin-bottom: 10px;
    margin-left: 5px;
    width: 98%;
    padding: 25px 15px;
    border-bottom: 0.5px solid #d9d9d9;
  }
  .cardText2 {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .smallCards {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      margin: 10px;
      .d1,
      .d2 {
        height: 180px;
        border: 1px solid #d9d9d9;
        width: 100%;
        .dDraft,
        .dDraft1 {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "Inria Serif";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          color: #8c673e;
          padding-top: 8px;
        }
        p {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "Inria Serif";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          color: #000;
          margin-top: 4px;
        }
      }
      .d2 {
        margin-left: 5px;
      }
    }
    .Number {
      font-family: "Inria Serif";
      font-style: normal;
      font-weight: 700;
      font-size: 23px;
      line-height: 22px;
      /* identical to box height */

      color: #262d22;
      margin-top: 5px;
    }
    svg {
      width: 40px;
      height: 40px;
    }
  }
}
.Card21 {
  border: 0.5px solid #d9d9d9;
  height: 250px;
  .cardText21 {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    padding: 10px 20px;

    .Number {
      font-family: "Inria Serif";
      font-style: normal;
      font-weight: 700;
      font-size: 23px;
      line-height: 22px;
      /* identical to box height */

      color: #262d22;
      margin-top: 5px;
    }
    svg {
      width: 40px;
      height: 40px;
    }
  }
  .cardHeader {
    background: #ffffff;
    border-bottom: 0.5px solid #d9d9d9;
    border-radius: 4px;
    height: 60px;
    align-items: center;
    display: flex;
    padding-left: 20px;
    font-family: "Inria Serif";
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 18px;
    /* identical to box height */

    color: #1a1a1a;
  }
  .cardHead {
    font-family: "Inria Serif";
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 18px;
    color: #8c673e;
    margin-bottom: 10px;
    margin-left: 5px;
    width: 98%;
    padding: 25px 15px;
    border-bottom: 0.5px solid #d9d9d9;
  }
  .cardText2 {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    .smallCards {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      margin: 10px;
      .d1,
      .d2 {
        height: 140px;
        border: 1px solid #d9d9d9;
        width: 100%;
        .dDraft,
        .dDraft1 {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-family: "Inria Serif";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          color: #8c673e;
          padding-top: 15px;
          padding-left: 15px;
        }
        p {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "Inria Serif";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          color: #000;
          margin-top: 4px;
        }
      }
    }
    .Number {
      font-family: "Inria Serif";
      font-style: normal;
      font-weight: 700;
      font-size: 23px;
      line-height: 22px;
      /* identical to box height */

      color: #262d22;
      margin-top: 5px;
    }
    svg {
      width: 40px;
      height: 40px;
    }
  }
}
.CardPie {
  border: 0.5px solid #d9d9d9;
  height: 460px;
  .cardHeader {
    background: #ffffff;
    border-bottom: 0.5px solid #d9d9d9;
    border-radius: 4px;
    height: 60px;
    align-items: center;
    display: flex;
    padding-left: 20px;
    font-family: "Inria Serif";
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 18px;
    /* identical to box height */

    color: #1a1a1a;
  }
  .cardHead {
    font-family: "Inria Serif";
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 18px;
    color: #8c673e;
    margin-bottom: 10px;
    margin-left: 5px;
    width: 98%;
    padding: 25px 15px;
    border-bottom: 0.5px solid #d9d9d9;
  }
  .cardText2 {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .smallCards {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      margin: 10px;
      .d1,
      .d2 {
        height: 180px;
        border: 1px solid #d9d9d9;
        width: 100%;
        .dDraft,
        .dDraft1 {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "Inria Serif";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          color: #8c673e;
          padding-top: 8px;
        }
        p {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "Inria Serif";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          color: #000;
          margin-top: 4px;
        }
      }
      .d2 {
        margin-left: 5px;
      }
    }
    .Number {
      font-family: "Inria Serif";
      font-style: normal;
      font-weight: 700;
      font-size: 23px;
      line-height: 22px;
      /* identical to box height */

      color: #262d22;
      margin-top: 5px;
    }
    svg {
      width: 40px;
      height: 40px;
    }
  }
}
// .Card3 {
//   border: 0.5px solid #d9d9d9;
//   height: 400px;
//   .cardHeader {
//     background: #ffffff;
//     border-bottom: 0.5px solid #d9d9d9;
//     border-radius: 4px;
//     height: 60px;
//     align-items: center;
//     display: flex;
//     justify-content: space-between;
//     padding-left: 20px;

//     padding-right: 20px;
//     .text {
//       font-family: "Inria Serif";
//       font-style: normal;
//       font-weight: 700;
//       font-size: 19px;
//       line-height: 18px;
//       /* identical to box height */

//       color: #1a1a1a;
//     }
//   }
// }
// .Card4 {
//   border: 0.5px solid #d9d9d9;
//   height: 485px;
//   .cardHeader {
//     background: #ffffff;
//     border-bottom: 0.5px solid #d9d9d9;
//     border-radius: 4px;
//     height: 60px;
//     align-items: center;
//     display: flex;
//     justify-content: space-between;
//     padding-left: 20px;

//     padding-right: 20px;
//     .text {
//       font-family: "Inria Serif";
//       font-style: normal;
//       font-weight: 700;
//       font-size: 19px;
//       line-height: 18px;
//       /* identical to box height */

//       color: #1a1a1a;
//     }
//     .btnView {
//       background: #1a1a1a;
//       border-radius: 4px;
//       font-family: "Inria Serif";
//       font-style: normal;
//       font-weight: 400;
//       font-size: 15px;
//       line-height: 18px;
//       padding: 5px 20px;
//       /* identical to box height */

//       color: #ffffff;
//     }
//   }
// }
// .Card5 {
//   border: 0.5px solid #d9d9d9;
//   height: 750px;
//   .cardHeader {
//     background: #ffffff;
//     border-bottom: 0.5px solid #d9d9d9;
//     border-radius: 4px;
//     height: 60px;
//     align-items: center;
//     display: flex;
//     justify-content: space-between;
//     padding-left: 20px;

//     padding-right: 20px;
//     .text {
//       font-family: "Inria Serif";
//       font-style: normal;
//       font-weight: 700;
//       font-size: 19px;
//       line-height: 18px;
//       /* identical to box height */

//       color: #1a1a1a;
//     }
//     .btnView {
//       background: #1a1a1a;
//       border-radius: 4px;
//       font-family: "Inria Serif";
//       font-style: normal;
//       font-weight: 400;
//       font-size: 15px;
//       line-height: 18px;
//       padding: 5px 20px;
//       /* identical to box height */

//       color: #ffffff;
//     }
//   }
// }
.dashboardP {
  margin: 20px;
}
.progress {
  height: 13px;
  margin-top: 2px;
}
.ProgressRow {
  margin-top: 30px;
  padding: 0px 20px;
}
.ProgressRow1 {
  display: none;
  margin-top: 30px;
  padding: 0px 20px;
}
.dashboardP {
  height: 800px;
  overflow-y: auto;
}
.heightProduct {
  height: 700px;
}
@media (max-width: 640px) {
  .progress {
    height: 10px;
  }
  .dashboardP {
    overflow-y: unset;
  }
  .heightProduct {
    height: 420px;
  }
  .Card21 {
    height: 170px;
    .cardHead {
      padding: 14px;
      margin-bottom: 0px !important;
    }

    .smallCards {
      .d1,
      .d2 {
        height: 90px !important;
        .cardText21 {
          margin-top: unset !important;
        }
      }
    }
  }
  .Card1,
  .Card2,
  .Card3,
  .Card4,
  .Card5,
  .Card21 {
    svg {
      width: 28px !important;
    }
    .cardHead {
      font-size: 14px;
    }
    .cardHeader {
      font-size: 14px;
      .text,
      .btnView {
        font-size: 14px;
      }
    }
    .cardText {
      font-size: 12px;
    }
    .cardText2 {
      .Number {
        font-size: 16px;
      }
      .dDraft,
      .dDraft1 {
        font-size: 12px !important;
      }
    }
  }
  .ProgressRow {
    display: none;
  }
  .ProgressRow1 {
    display: contents;
    .Pcol {
      display: flex;
      justify-content: space-between;
      padding-bottom: 5px;
      padding-top: 10px;
      margin-top: 10px;
    }
  }
}
#apexchartswtleykpg {
  display: flex;
  justify-content: center;
  width: 100% !important;
}
