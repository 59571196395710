.verticlemenuCard {
  // width: 280px;
  // height: 100%;
  background-color: #404b3b;
  top: -81px;
  .LogoImage {
    // background-color: #1a1a1a;
    height: 210px;
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      margin-top: 20px;
      width: 100%;
      height: 100%;
    }
  }
}
.parentList {
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
  .verticleNameP {
    padding: 15px 15px;
    white-space: nowrap;

    .verticleName {
      margin-left: 10px;
      color: #fff;
      font-size: 16px;
      position: relative;
      top: 5px;
    }
  }
}
.verticlemenuCardMini {
  // width: 280px;
  // height: 100%;
  background-color: #404b3b;
  top: -81px;
  .LogoImage {
    // background-color: #1a1a1a;
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      margin-top: 15px;
      width: 100%;
    }
  }
}
.parentListMini {
  // margin-top: 30px;
  // margin-left: 20px;
  // margin-right: 20px;
  padding: 20px 0px;
  .verticleNameP {
    padding: 15px;
    margin: 0px 8px;
    white-space: nowrap;
    text-align: center;
    .verticleName {
      margin-left: 10px;
      color: #fff;
      font-size: 20px;
    }
  }
}

.verticleNameP:active,
.verticleNameP:hover,
.verticleNameP:focus {
  background-color: #262d22;
  cursor: pointer;
}
.verticleNamePOnclick {
  background-color: #262d22;
}
// .HeaderStyle {
//   display: flex;
//   justify-content: space-between;
//   margin: 20px;
//   // .btn-secondary {
//   //   // background-color: #fff !important;
//   //   // color: #000;
//   //   width: 41px;
//   //   flex-wrap: nowrap;
//   //   border-radius: 10%;
//   //   font-size: 23px;
//   //   color: #fff;
//   //   font-weight: 900;
//   //   padding: 0px !important;
//   //   background-color: #C59C6C !important;
//   // }
// }
.HeaderStyle {
  display: flex;
  justify-content: space-between;
  margin: 20px;
  .btn-secondary {
    background-color: #fff !important;
    color: #000;
    // width: 200px;
    border: none;
    img {
      width: 25px;
      height: 25px;
      border-radius: 5px;
      margin-right: 5px;
    }
    .imageBtn {
      height: 30px;
      width: 30px;
      flex-wrap: nowrap;
      border-radius: 5px;
      font-size: 14px;
      color: #fff;
      font-weight: 900;
      padding: 0px !important;
      background-color: #c59c6c !important;
      margin-right: 5px;
    }
    // .imageBdDiv{
    //   width: 25px;
    //   height: 25px;
    //   margin-right: 10px;
    //   // background-color: #000;
    //   img{
    //     border-radius: 50%;
    //     margin-right: -10px;
    //   }
    // }
  }
}

.hrLineProfile {
  width: 70%;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #b9b9b9;
  margin-left: 15%;
}
.DropdownButton {
  padding-top: 15px;
}
a,
a:hover {
  color: #404b3b;
}
// date picker
.rs-anim-fade.rs-anim-in {
  left: 67% !important;
  top: 159px !important;
}
.rs-picker-toggle-wrapper {
  width: 100%;
}
.rs-picker-toggle.rs-btn.rs-btn-default {
  background: #fff !important;
  border: 0.5px solid #d9d9d9 !important;
  border-radius: 4px;
}
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  display: none;
}
.MyIcon {
  position: relative;
  right: -28px !important;
  top: -1px;
}
@media (max-width: 640px) {
  .routeSpan {
    position: relative;
    top: 8px;
  }
  .verticlemenuCard {
    .LogoImage {
      img {
        margin-top: unset;
      }
    }
  }
  .parentList {
    .verticleNameP {
      padding: 15px 15px;
      svg {
        height: 17px;
      }
      .verticleName {
        font-size: 14px;
        top: 2px;
      }
    }
  }
}
@media (max-width: 480px) {
  .HeaderStyle {
    margin: 20px 10px;
    .btn-secondary {
      width: 160px;
    }
    .backbutton {
      svg {
        width: 23px;
      }
    }
  }
}
