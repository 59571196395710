.ProductCertificationPage {
  position: relative;
  display: flex;
  justify-content: center;
  .Section1 {
    background-image: url("../assets/images/PatternHead.png");
    width: 480.49px;
    height: 734px;
    position: absolute;
  }
  .hastakalaParmank {
    position: absolute;
    width: 151px;
    height: 92px;
    z-index: 99;
    // left: 164px;
  }
  .SC1Text {
    z-index: 99;
    position: absolute;
    top: 103px;
    color: #fff;
    h3 {
      color: #fff;
      text-align: center;
      font-family: Playfair Display;
      font-size: 36px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 43.2px */
      letter-spacing: 0.36px;
    }
    p {
      color: #fff;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
    .reactangle-1 {
      // position: relative;

      width: 343px;
      height: 241px;
      flex-shrink: 0;
      border-radius: 12px;
      background: linear-gradient(180deg, #fff 0%, #fff7d9 100%);
      .reactangle-11 {
        text-align: center;
        .authBadge {
          position: absolute;
          width: 144px;
          height: 144px;
          margin-top: 20px;
          // bottom: -64px;
          left: 98px;
        }
        .hastakalaLogo {
          margin-top: 15px;
          // position: absolute;
          width: 106px;
          height: 52px;
          flex-shrink: 0;
        }
      }
      .reactangle-12 {
        display: flex;
        justify-content: center;
        .PName {
          // text-align: center;
          // position: absolute;
          z-index: 100;
          width: 231px;
          color: #362706;
          text-align: center;
          font-family: Playfair Display;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 25.2px */
          letter-spacing: 0.36px;
        }
      }
    }
  }
}

@media (min-width: 381px) and (max-width: 640px) {
  .ProductCertificationPage {
    .hastakalaParmank {
      left: 38% !important;
    }
  }
}
@media (max-width: 380px) {
  .ProductCertificationPage {
    .hastakalaParmank {
      width: 100px;
      height: 70px;
      left: 42% !important;
    }
  }
}

// DesignAgency Product css start
.DesignAgencyParent {
  // display: flex;
  // justify-content: center;
  position: relative;
  .PImag {
    width: 100%;
    height: 100vh;
  }
  .RowParent {
    // display: inline-grid;
    // justify-content: center;
    // padding-left: 54px;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    left: 0;
    top: 15%;
    bottom: 17%;
    right: 0;
    padding-right: 25px;
    overflow: hidden;
    .RowDidP {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: -webkit-fill-available;
      min-height: fit-content;
      .RowDiv {
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: auto;
        align-items: baseline;
        // padding-top: 4%;
        // padding-bottom: 5px;
        .Text1,
        .Text2,
        .Text3 {
          font-family: "Bebas Neue", sans-serif;
          font-style: normal;
          font-size: 14px;
          font-weight: 400;
          text-align: start;
          color: #fff;
          line-height: 18px;

          white-space: nowrap;
          display: flex;
          justify-content: start;
          align-items: baseline;
        }
        .Text1 {
          width: 50% !important;
          padding-left: 10%;
          white-space: nowrap;
        }
        .Text2 {
          width: 48% !important;
        }
        .Text3 {
          width: 2%;
        }
      }
    }
  }
  .designAgencyProductPage {
    background-image: url("../assets/images/productCertification.jpeg");
    height: fit-content;
    min-height: 100vh;
    width: 480px;
    overflow: auto;
    background-size: contain;
    background-repeat: no-repeat;
    padding-top: 134px;
    padding-bottom: 100px;

    .RowTextProA {
      height: 83px;
      width: 100%;
      margin-left: 0px;
      align-items: center;
      .ColTextPro {
        height: 50px;
        margin-top: 10px;
      }
      .ColTextPro1 {
        height: 50px;
        margin-top: 10px;
        text-transform: capitalize;
      }
      .Text {
        font-family: "Bebas Neue", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        align-items: center;
        display: flex;
        height: 50px;
        text-align: center;
        color: #fff;
      }
    }
  }
}
@media (max-width: 359px) {
  .DesignAgencyParent {
    .RowParent {
      padding-left: 10px;
      .RowDidP {
        .RowDiv {
          .Text1,
          .Text2 {
            font-size: 12px;
            // padding-left: 5%;
          }
          // .Text1 {
          //   padding-left: 42px;
          // }
          .Text3 {
            font-size: 17px;
          }
        }
      }
    }
  }
}
@media (min-width: 360px) and (max-width: 380px) {
  .DesignAgencyParent {
    .RowParent {
      padding-left: 10px;
      .RowDidP {
        .RowDiv {
          .Text1,
          .Text2 {
            font-size: 12px;
            // padding-left: 5%;
          }
          .Text1 {
            width: 50% !important;
          }
          .Text3 {
            font-size: 17px;
          }
        }
      }
    }
  }
}
@media (min-width: 381px) and (max-width: 414px) {
  .DesignAgencyParent {
    .RowParent {
      padding-left: 10px !important;
      .RowDidP {
        .RowDiv {
          // padding-top: 10px;
          .Text1,
          .Text2 {
            font-size: 15px;
            // padding-left: 5%;
          }
          .Text3 {
            font-size: 17px;
          }
          .Text1 {
            width: 55% !important;
          }
        }
      }
    }
  }
}
@media (min-width: 415px) and (max-width: 479px) {
  .DesignAgencyParent {
    .RowParent {
      padding-left: 2% !important;
      .RowDidP {
        .RowDiv {
          // padding-top: 8px;
          .Text1,
          .Text2 {
            font-size: 15px;
            // padding-left: 5%;
          }

          .Text3 {
            font-size: 19px;
          }
        }
      }
    }
  }
}
@media (min-width: 480px) and (max-width: 550px) {
  .DesignAgencyParent {
    .RowParent {
      padding-left: 10px;
      .RowDidP {
        .RowDiv {
          // padding-top: 10px;
          .Text1,
          .Text2 {
            font-size: 15px;
          }
          .Text3 {
            font-size: 19.5px;
          }
        }
      }
    }
  }
}
@media (min-width: 551px) and (max-width: 640px) {
  .DesignAgencyParent {
    .RowParent {
      padding-left: 10px;
      .RowDidP {
        .RowDiv {
          // padding-top: 15px;
          .Text1,
          .Text2 {
            font-size: 15px;
          }
          .Text3 {
            font-size: 19px;
          }
        }
      }
    }
  }
}
@media (min-width: 640px) {
  .DesignAgencyParent {
    width: 480px;
    margin: auto;
    .RowParent {
      padding-left: 10px;
      .RowDidP {
        .RowDiv {
          // padding-top: 10px;

          .Text1,
          .Text2 {
            font-size: 15px;
          }
          .Text3 {
            font-size: 19px;
          }
        }
      }
    }
  }
}
@media (max-height: 800px) {
  .DesignAgencyParent {
    .RowParent {
      top: 143px;
      bottom: 142px;
    }
  }
}
@media (min-height: 801px) and (max-height: 1024px) {
  .DesignAgencyParent {
    .RowParent {
      top: 143px;
      bottom: 142px;
    }
  }
}
@media (min-height: 1025px) and (max-height: 1300px) {
  .DesignAgencyParent {
    .RowParent {
      top: 173px;
      // bottom: 152px;
    }
  }
}
// DesignAgency Product css end

// product success flow page
.authentication {
  display: flex;
  justify-content: center;
  .authImag {
    max-width: fit-content;
  }
  // background-image: url("../assets/images/Group\ 1000004664.png");
  // height: 896px;
  // width: 480px;

  .hastakalaParmank {
    position: absolute;
    width: 151px;
    height: 92px;
    z-index: 99;

    margin-top: 49px;
    // left: 164px;
  }
  .SC1Text {
    z-index: 99;
    position: absolute;
    top: 179px;
    color: #fff;
    .hastakalaParmank {
      position: absolute;
      width: 151px;
      height: 92px;
      z-index: 99;
      top: -114px;
      left: 114px;
      // margin-top: 49px;
      // left: 164px;
    }
    h3 {
      color: #fff;
      text-align: center;
      font-family: Playfair Display;
      font-size: 36px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 43.2px */
      letter-spacing: 0.36px;
    }
    .AuthDiv {
      width: 374px;
      height: 191px;
      border-radius: 8px;
      background: #fff;
      top: 67px;
      position: relative;
      padding: 20px;
      h4 {
        color: #1c1c1c;
        text-align: center;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
      }
      button {
        display: flex;
        padding: 16px;
        justify-content: center;
        align-items: center;
        flex: 1 0 0;
        border-radius: 8px;
        background: #0084ff;
        width: 100%;
        margin-top: 26px;
        color: #fff;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
      }
    }
  }
}
.productSuccessFlow,
.productfakeAlert,
.productVerify {
  margin: auto;
  width: 414px;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: #f8fafc;
  .fakeAlertSubDIV {
    height: 300px;
    position: relative;
    top: 30%;
    bottom: 40%;
  }
  .RequiredField {
    color: red !important;
    margin-top: 0px !important;
  }
  .mrg-100 {
    margin-top: 300px;
  }
  .expires {
    width: 330px;
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-top: 20px !important;
    span {
      color: rgba(0, 132, 255, 0.5);
      text-align: right;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }
  .InputsOtpDIv {
    display: flex;
    justify-content: space-between;
    margin-top: 20px !important;
    width: 100%;
    height: 52px;
    margin: auto;
    input {
      display: flex;
      width: 51px !important;
      height: 52px;
      padding: 16px;
      align-items: center;
      gap: 8px;
      flex-shrink: 0;
      border-radius: 8px;
      border: 1px solid #e2e8f0;
      background: #fff;
    }
  }
  .backIcon {
    margin-top: 30px;
    margin-left: 30px;
  }
  .PleaseShareDiv {
    width: 350px;
    margin-top: 15px;
    margin-left: 30px;

    span {
      color: #1c1c1c;
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
      margin: auto;
    }
  }
  .InputFormDiv {
    position: relative;
    margin-left: 30px;
    margin-top: 20px;
    width: 350px;
    .inputform {
      position: relative;

      .locationIcon {
        bottom: 17px;
        right: 20px;
      }
      .div91 {
        bottom: 17px;
        left: 20px;
      }
    }

    .locationIcon,
    .div91 {
      position: absolute;
      color: #585757;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      z-index: 99;
    }

    label {
      color: #1c1c1c;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
    }
    input {
      display: inline-flex;
      padding: 16px;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      border: 1px solid #e2e8f0;
      background: #fff;
      color: #585757;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
    ::placeholder {
      color: rgba(88, 87, 87, 0.5);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
    p {
      color: rgba(28, 28, 28, 0.7);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-top: 10px;
    }
    textarea {
      resize: none;
      height: 128px;
    }
  }
  .InputFormDiv1 {
    position: relative;
    margin-top: 20px;
    width: 350px;
    .inputform {
      position: relative;
    }

    .locationIcon,
    .div91 {
      position: absolute;
      color: #585757;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      z-index: 99;
    }

    label {
      color: #1c1c1c;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
    }
    input {
      display: inline-flex;
      padding: 16px;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      border: 1px solid #e2e8f0;
      background: #fff;
      color: #585757;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
    ::placeholder {
      color: rgba(88, 87, 87, 0.5);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
    p {
      color: rgba(28, 28, 28, 0.7);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-top: 10px;
    }
    textarea {
      resize: none;
      height: 128px;
    }
  }
  .HkvDiv4,
  .HkvDiv,
  .HkvDiv1 {
    display: flex;
    justify-content: center;
    .HkvBadge {
      width: 170px;
      height: 56px;
    }
  }
  .HkvDiv3 {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding-right: 62px;
  }
  .HkvDiv {
    margin-top: 300px;
  }
  .HkvDiv1 {
    margin-top: 10px;
    margin-bottom: 40px;
  }
  .HkvDiv4 {
    bottom: 40px;
    position: fixed;
    width: inherit;
  }
  .sendButtonDiv {
    padding: 30px;
    top: 300px;
    position: relative;
    button {
      border-radius: 8px;
      background: #0084ff;
      color: #fff;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      min-width: 100%;
      height: 40px;
    }
  }
  .reportAndLine {
    width: 350px;
    margin: auto;
    svg {
      margin-top: 30px;
    }
    h4 {
      color: #1c1c1c;
      text-align: center;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
      margin-top: 30px;
    }
  }
}
.productfakeAlert {
  .fakealDiv {
    // height: 129px;
    // margin-top: 179px;
  }
  .fakealDiv1,
  .fakealDiv {
    display: flex;
    justify-content: center;
    align-items: end;
  }
  .fakealDiv1 {
    height: 200px;
  }
  .fakealDiv2 {
    // width: 300px;
    height: 130px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    h3 {
      color: #000;
      text-align: center;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 125%; /* 30px */
      text-transform: capitalize;
    }
  }
  .fakealDivE {
    // width: 300px;
    // height: 130px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    h4 {
      margin-top: 20px;
      color: #000;
      text-align: center;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 125%; /* 30px */
      text-transform: none;
    }
    p {
      margin-top: 10px;
      color: #000;
      text-align: center;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 125%; /* 30px */
      text-transform: none;
    }
  }
  .fakeAlInfo {
    width: 269px;
    margin: auto;
    margin-top: 30px;
    text-align: center;
    h3 {
      color: #1c1c1c;
      text-align: center;
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
    }
    span {
      color: rgba(28, 28, 28, 0.7);
      text-align: center !important;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      letter-spacing: -0.032px;
      text-align: center;
      margin-top: 30px !important;
    }
    button {
      border-radius: 8px;
      background: #0084ff;
      display: flex;
      padding: 16px;
      justify-content: center;
      align-items: center;
      flex: 1 0 0;
      margin-top: 40px !important;
      margin: auto;
      width: 269px;
    }
  }
}
.productSuccessFlow {
  position: relative;
  .sendButtonDivUser {
    padding: 30px;
    margin-top: 100px;
    // bottom: 40px;
    // position: absolute;
    button {
      border-radius: 8px;
      background: #0084ff;
      color: #fff;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      min-width: 100%;
      height: 40px;
    }
  }
}
// verify product
.productVerify {
  position: relative;
  overflow: hidden;

  .productVerifySub {
    height: 440px;
    padding-top: 60px;
    .gifDiv {
      width: 100%;
      display: flex;
      justify-content: center;
      .gif {
        width: 260px;
        height: 200px;
        flex-shrink: 0;
      }
    }
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .productSuccessFlow,
  .productfakeAlert,
  .authentication {
    width: 100%;
    .InputFormDiv {
      width: 100%;
      padding-right: 50px;
    }
  }
}
@media (max-width: 480px) {
  .productSuccessFlow,
  .productfakeAlert {
    .InputsOtpDIv,
    .expires {
      width: 100%;
      // padding-left: 30px;
      padding-right: 30px;
    }
  }
  .productSuccessFlow,
  .productfakeAlert,
  .authentication {
    margin: unset;
    width: 100%;
    // height: 896px;
  }
  .authentication {
    .SC1Text {
      .AuthDiv {
        width: 350px;
      }
    }
  }
}
@media (min-width: 381px) and (max-width: 480px) {
  .productSuccessFlow,
  .productfakeAlert {
    .InputFormDiv {
      input,
      textarea {
        width: 100%;
      }
    }
    .fakealDiv2 {
      padding: 10px;
      h3 {
        font-size: 16px;
      }
    }
  }
  .authentication {
    .hastakalaParmank {
      left: 35%;
    }
  }
}
@media (max-width: 379px) {
  .productSuccessFlow,
  .productfakeAlert {
    .fakealDiv2 {
      padding: 10px;
      h3 {
        font-size: 16px;
      }
    }
    .InputFormDiv {
      input,
      textarea {
        width: 80%;
      }
      .inputform {
        .locationIcon {
          right: 87px;
        }
      }
    }
    // .InputsOtpDIv,
    // .expires {
    //   width: 268px;
    // }
  }
  .authentication {
    overflow-x: hidden;
    .hastakalaParmank {
      left: 29.5%;
    }
    .SC1Text {
      left: 15px;
      .AuthDiv {
        width: 330px;
      }
    }
  }
}
