@import "./src/common/commontheme.scss";
@import "./pages/artform/artformtheme.scss";
@import "./pages/category/catogerytheme.scss";
@import "./pages/dashboard/dashboardtheme.scss";
@import "./pages/products/producttheme.scss";
@import "./pages/sub-category/subCategorytheme.scss";
@import "./pages/type/typetheme.scss";
@import "./pages/users/usersTheme.scss";
@import "./pages/weave-type/weaveTypeTheme.scss";
@import "./pages/login/logintheme.scss";
@import "react-notifications-component/dist/theme.css";
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css");

@import "./pages/products/certification-page/ProductNewCerticatecss.scss";

body {
  overflow-x: hidden;
}

.CardHeaderStyle {
  position: sticky;
  z-index: 999;
  top: 0;
}

body.modal-open {
  overflow: hidden !important;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 95vh;
  overflow-x: unset;
  overflow-y: unset;
  outline: 0;
}

.CircleLoder {
  height: 130px;
}

.sideArrow {
  left: 81px;
}

.sideArrow1 {
  left: 250px;
}

.previewno {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}

.sideArrow,
.sideArrow1 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 500px;
  width: 25px;
  height: 60px;
  background-color: #404b3b;
  border-radius: 5px;
  cursor: pointer;
  z-index: 99;
}

.noListingPage1,
.noListingPage2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.noListingPage1 {
  height: 370px;
}

.noListingPage2 {
  height: 500px;
}

.fade {
  background-color: #1a1a1a;
  opacity: 98%;
}

.ResetBtn {
  background-color: #1a1a1a;
  height: 40px;
}

.react-select--is-disabled {
  border: 1px solid #ced4da;
  height: min-content;
  border-radius: 5px;
}

// .react-select__option{
//   background-color: #c59c6c !important;
// }
// .text-capitalize{
//   text-transform: capitalize !important;
// }
.req {
  color: red;
  text-align: center;
  align-items: center;
}

.rs-picker-default .rs-picker-toggle {
  z-index: 0;
}

.rnc__notification-item--success {
  border-left: none;

  .rnc__notification-content {
    background: #404b3b;
  }

  .rnc__notification-timer {
    background: #404b3b;
  }
}

.rnc__notification-item--danger {
  background-color: #740b15;
  border-left: 8px solid #740b15;

  .rnc__notification-timer {
    background: #740b15;
  }
}

#my-sidebar-mobile {
  display: none;
}

.no-gutters {
  padding-left: 10px;
}

.BackIcon {
  width: 57px;
  background-color: #404b3b;
  color: #fff;
  font-size: 22px;
  margin-top: 10px;
  margin-left: 20px !important;
  cursor: pointer;
}

#contentCard {
  margin-left: 82px;
  width: 100%;
  min-width: -webkit-fill-available;
}

.PageContent {
  display: block;
}

.cursor-pointer {
  cursor: pointer;
}

.RequiredField {
  color: red;
  padding-top: 10px;
}

// react selector
.react-select__menu {
  background-color: #fff !important;
  color: #000 !important;
  text-transform: capitalize;
  cursor: pointer;
}

.react-select__control {
  text-transform: capitalize;
  cursor: pointer;
  background: #f5f5f5 !important;
}

.ConfirmModal {
  .modal-content {
    padding: 40px;
    top: 200px;
  }

  .modal-title {
    width: 100%;
  }

  .modal-footer {
    border-top: unset;
    justify-content: flex-start;
  }

  .textCapitalize {
    text-transform: capitalize;
  }

  .NameHeader {
    font-family: "Inria Serif";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #1a1a1a;
    margin-top: 10px;
  }

  .Heading {
    display: flex;
    justify-content: space-between;

    // margin: 0px 20px;
    h3 {
      font-family: "Inria Serif";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 18px;
      /* identical to box height */

      color: #1a1a1a;
    }

    .ContentDiv {
      font-family: "Inria Serif";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 18px;
      /* identical to box height */

      color: #1a1a1a;
    }

    svg {
      cursor: pointer;
    }
  }

  .BtnFlex {
    display: flex;
    justify-content: flex-start;

    .btnY1,
    .btnN1 {
      margin-right: 10px;
      padding: 10px;
    }

    .btnY1 {
      background: #c69c6d;
      border-radius: 4px;
      min-width: 100%;
    }

    .btnN1 {
      background: #1a1a1a;
      border-radius: 4px;
      min-width: 100%;
    }
  }
}

.react-select__control {
  background-color: #fff !important;
}

// Modals
.OrganizationModal {
  .modal-content {
    top: 4px;
  }
}

.CreateUserModal {
  .modal-content {
    height: 95vh;
  }

  .modal-body {
    overflow-y: auto;
  }
}

.TransferModal {
  .modal-content {
    margin-top: 260px !important;
    margin: auto;
  }

  label {
    text-transform: capitalize;
    font-size: 16px;
    font-family: Roboto;
  }

  .btnS {
    button {
      background-color: #1a1a1a;
      width: 100%;
      margin-top: 20px;
    }
  }
}

@media (min-width: 768px) {
  .TransferModal {
    .modal-content {
      margin-top: 260px !important;
      width: 500px;
      margin: auto;
    }
  }
}

.TransferModal,
.QRModal,
.ArtFormModal,
.TypeModal,
.CategoryModal,
.SubCategoryModal,
.WeaveTypeModal,
.OrganizationModal,
.CreateUserModal {
  .modal-content {
    padding: 40px;
  }

  .modal-title {
    width: 100%;
  }

  .Heading {
    display: flex;
    justify-content: space-between;

    // margin: 0px 20px;
    h3 {
      font-family: "Inria Serif";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 18px;
      /* identical to box height */

      color: #1a1a1a;
    }

    .ContentDiv {
      font-family: "Inria Serif";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      /* identical to box height */

      color: #1a1a1a;
    }

    svg {
      cursor: pointer;
    }
  }

  // .modal-header {
  //   border-bottom: unset;
  // }
  .modal-footer {
    border-top: unset;
  }

  .AddArtform,
  .AddTypeForm,
  .AddCategoryForm,
  .AddSubCategoryForm,
  .AddWeaveTypeForm,
  .createUser {
    .form {
      .RowForm {
        .formDiv {
          margin-top: 10px;
          margin-bottom: -20px;
        }
      }

      .RowForm1 {
        .formDiv {
          margin-top: 50px;
          margin-bottom: 0px;
        }
      }

      label {
        font-size: 20px;
        color: #1a1a1a;
        font-weight: 400;

        span {
          color: red;
        }
      }

      .formDiv {
        margin-top: 40px;
        margin-bottom: 40px;
      }

      .formDiv1 {
        margin-top: 20px;
      }

      .form-control {
        padding: 10px;
        height: 50px;
        text-transform: capitalize;
        cursor: pointer;
      }

      .React_selector {
        input {
          height: 40px;
          cursor: pointer;
        }
      }
    }

    .artButton {
      padding: 10px 20px;
      background: #1a1a1a;
      margin-top: 40px;
      width: 50%;
      height: 50px;
    }
  }
}

.InputColP2 {
  display: none;
}

.InputColP1 {
  display: flex;
  margin: 20px 8px;
  width: auto;
  cursor: pointer;
}

.InputColP {
  display: flex;
  margin: 20px 8px;
  width: 100%;
  cursor: pointer;

  .InputDiv {
    display: flex;
    position: relative;
    width: 100%;

    input {
      background-color: #fff;
      // background: #f5f5f5;
      border: 0.5px solid #d9d9d9;
      border-radius: 4px;
      width: 100%;
      padding-left: 10px;
    }

    .InputSvg {
      margin-top: 8px;
      position: absolute;
      right: 8px;
    }
  }

  .addParty {
    background-color: #1a1a1a;
    float: right;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.tableParent,
.tableParent1 {
  // border: 1px solid #d9d9d9;
  margin: 20px;

  .tableHeaderRow {
    align-items: center;
    text-align: start;
    border-bottom: 1px solid #d9d9d9;
    padding: 20px 0px;
    margin-left: 0px;
    // width: 100%;
    justify-content: center;
    display: inline-flex;
    color: #ffffff;
    font-weight: 400;
    font-size: 18px;
    font-family: "Inria Serif";
    line-height: 18px;
    background: #404b3b;
    border-radius: 4px;
  }

  .tableBodyDeatils {
    padding: 5px 4px;
    // height: 58px;
    align-items: center;
    display: grid;
    // width: 99%;
    margin-left: 6px;
    margin-right: 2px;
    background-color: rgb(255, 255, 255);
    // border-bottom: 1px solid #d9d9d9;
    text-transform: capitalize;
  }

  .EvenRow,
  .OddRow {
    height: auto;
    align-items: center !important;
  }

  .EvenRow {
    background: #ffffff;
  }

  .OddRow {
    background: #f8f8f8;
  }

  .IndexDatatableNeoCol {
    cursor: pointer;
    font-size: 15px;
    font-weight: 400;
    font-family: "Inria Serif";
    flex-flow: row nowrap;
    color: #1a1a1a;
    line-height: 18px;
    padding: 15px 10px;

    .logoImage {
      display: inline-block;
      width: 50px;
      height: 50px;
      border: 0.5px solid rgb(109, 99, 99);
      border-radius: 5px;
    }

    .logoPreview {
      display: inline-block;
      width: 50px;
      height: 50px;
      font-size: 8px;
      padding: 3px;
      box-sizing: border-box;
      border: 0.5px solid rgb(109, 99, 99);
      border-radius: 5px;
    }

    .tableBtnsProduct {
      float: right;
      padding-right: 5px;
    }

    .ProductFlowStatusParent {
      padding-right: 10px;

      position: relative;

      .hr {
        width: 90%;
        position: absolute;
        top: 11px;
        height: 1px;
        border: 0;
        border-top: 2px double #0e0d14;
        padding-right: 10px;
      }

      .ProductFlowStatus {
        display: flex;
        justify-content: space-between;
        align-content: center;
        flex-direction: row;

        .ps1,
        .ps2,
        .ps3,
        .ps4,
        .ps5 {
          width: 25px;
          height: 25px;
          background-color: #fff;
          border-radius: 50%;
          border: 1px solid rgb(154, 140, 140);
          z-index: 99;

          .innerCircle {
            width: 15px;
            height: 15px;
            border: 1px solid rgb(154, 140, 140);
            border-radius: 50%;
            // top: 50%;
            // left: 50%;
            margin: auto;
            margin-top: 4px;
          }
        }
      }
    }
  }
}

.tableParent {
  .tableHeaderRow {
    width: 100%;
  }

  .tableBodyDeatils {
    width: 100%;
  }
}

.tableParent1 {
  width: 100%;
  overflow: auto;

  .tableHeaderRow {
    width: 130%;
  }

  .tableBodyDeatils {
    width: 130%;
  }
}

.tableParentDashboard {

  .EvenRow,
  .OddRow {
    height: auto;
    align-items: center;
    padding: 18px 5px;
  }

  .EvenRow {
    background: #ffffff;
  }

  .OddRow {
    background: #f8f8f8;
  }

  .tableHeaderRow {
    align-items: center;
    text-align: start;
    // border-bottom: 1px solid #d9d9d9;
    padding: 20px 0px;
    margin-left: 0px;
    width: 100%;
    justify-content: center;
    display: inline-flex;
    color: #fff;
    font-weight: 400;
    font-size: 18px;
    font-family: "Inria Serif";
    line-height: 18px;
    background: #404b3b;
  }

  .tableBodyDeatils {
    padding: 5px 4px;
    // height: 58px;
    align-items: center;
    display: grid;
    width: 99%;
    margin-left: 6px;
    margin-right: 2px;
    background-color: rgb(255, 255, 255);
    // border-bottom: 1px solid #d9d9d9;
    text-transform: capitalize;
  }

  .IndexDatatableNeoCol {
    cursor: pointer;
    font-size: 15px;
    font-weight: 400;
    font-family: "Inria Serif";
    flex-flow: row nowrap;
    color: #1a1a1a;
    line-height: 18px;
  }
}

.btns {
  color: #8c673e;
}

.actionLineHeight {
  border-left: 0.5px solid #c59c6c;
  height: 30px;
  margin-left: 10px;
}

.noListingPage {
  display: flex;
  justify-content: center;
  position: relative;
  top: 300px;
  color: #1a1a1a;
  font-weight: 400;
  font-size: 18px;
  font-family: "Inria Serif";
  line-height: 18px;
}

.paginationDiv {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 3px 13px;
  padding-left: 18px;

  span {
    font-size: 16px;
    font-weight: 600;
  }

  .Mui-selected {
    background-color: #404b3b !important;
    color: #ffff !important;
  }
}

.LoaderDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 300px;
}

.LoaderDiv1 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 100px;
}

.LoaderDivModal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.createUser,
.createProduct {

  // height: 800px;
  // overflow-y: auto;
  .imageDiv {
    position: relative;
    width: 200px;
    height: 200px;
    background: #f5f5f5;
    border: 0.5px dashed #c6c6c6;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    .DownloadIcon,
    .DownloadIcon1 {
      position: absolute;
      left: 5px;
      bottom: 5px;
      width: unset !important;
    }

    img {
      border: 1px solid #decdcd;
      border-radius: 5px;
    }

    svg {
      width: 100%;
      cursor: pointer;
    }

    .clickText {
      position: relative;
      left: 25px;
    }
  }

  .imageDiv2 {
    position: relative;
    width: 100%;
    height: 250px;
    background: #f5f5f5;
    border: 0.5px dashed #c6c6c6;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    .NoImages {
      .d1 {
        display: flex;
        justify-content: center;
      }
    }
  }

  .imageDiv1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .PreviewDivPart {
      display: flex;
      margin-top: 52px;
    }

    .PreIm {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 200px;
    }
  }

  .imageDiv12 {
    margin-left: 23px;
    margin-right: 23px;
    margin-bottom: 20px;
    display: inline-table;

    .PreviewDivPart1 {
      // display: flex;
      padding: 10px;
    }

    .varientCode {
      padding-top: 24px;
      padding-left: 24px;
      font-size: 16px;
    }

    .variantImageDiv {
      display: flex;
    }
  }

  .imageDiv1,
  .imageDiv12 {
    position: relative;
    width: 100%;
    height: 300px;
    background: #f5f5f5;
    border: 0.5px dashed #c6c6c6;
    border-radius: 4px;

    .imageDiv2 {
      border: none;
    }

    .divAddIcon {
      position: absolute;
      right: 12px;
      top: 10px;
      z-index: 999;
      display: flex;

      button {
        white-space: nowrap;
      }
    }

    svg {
      // width: 100%;
      cursor: pointer;
    }

    .clickText {
      position: relative;
      left: 25px;
    }

    img {
      margin-right: 10px;
      // width: 100%;
    }

    // iframe,
    // video {
    //   width: 100%;
    // }
  }

  .UserHeader {
    font-family: "Inria Serif";
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 18px;
    padding-left: 20px;
    padding-top: 40px;
    padding-bottom: 30px;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    justify-content: space-between;

    .editBtn {
      button {
        color: #1a1a1a;
        background-color: #c59c6c;
        margin-right: 20px;
        font-weight: 900;
      }
    }
  }

  .userPd {
    padding: 20px;
  }

  .UserDetailsDiv {
    border: 1px solid #d9d9d9;
    margin: 20px;
  }

  .form {
    label {
      font-family: Roboto;
      font-size: 16px;
      color: #1a1a1a;
      font-weight: 500;

      span {
        color: red;
      }
    }

    .variantBtn {
      background-color: #8c673e;
      color: #fff;
      margin-top: 30px;
      height: 50px;
      // width: 100%;
      // padding: 10p 40px;
    }

    .formDiv {
      margin-top: 40px;
      margin-bottom: 40px;
    }

    .formDiv12 {
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 900;

      span {
        text-transform: capitalize;
        padding-left: 10px;
        font-weight: 400 !important;
      }
    }

    .formDiv1 {
      margin-top: 20px;
    }

    .form-control {
      padding: 10px;
      height: 50px;
      text-transform: capitalize;
      cursor: pointer;
      font-family: Roboto;
    }

    .React_selector {
      input {
        height: 40px;
        cursor: pointer;
      }
    }
  }

  .artButton {
    padding: 10px 20px;
    background: #1a1a1a;
    margin-top: 40px;
    width: 25%;
    height: 50px;
  }
}

.headerDisplay {
  display: flex;
  justify-content: space-between;
  margin-right: 30px;
}

.onClickTab {
  cursor: pointer;
}

.svgIconPlus {
  position: absolute;
  right: -48%;
}

.textUpper {
  text-transform: uppercase !important;
}

.textLower {
  text-transform: lowercase !important;
}

.editPen {
  position: absolute;
  top: 8px;
  right: 8px;
}

.form-control::placeholder {
  text-transform: capitalize !important;
}

.ArryyIcon {
  width: 100%;
  margin: 10px;
  position: relative;
  cursor: pointer;

  .viewIconForPreview {
    z-index: 99;
    position: absolute;
    top: 5px;
    left: 5px;
    cursor: pointer;
  }

  .close {
    position: absolute;
    top: 10px;
    right: 18px;
    z-index: 999;
  }

  .showOrHide {
    position: absolute;
    left: 5px;
    bottom: 5px;
    z-index: 99;
  }

  .pinUnpin,
  .ViewPin {
    position: absolute;
    // left: 38px;
    bottom: 6px;
    z-index: 99;
    height: 24px;
    width: 25px;
    // color: black;
    font-weight: 1000;
    // padding: 4px;
    border-radius: 5px;
    background-color: white;
  }

  .pinUnpin {
    left: 38px;
  }

  .ViewPin {
    left: 68px;
  }

  .DownloadIcon {
    position: absolute;
    left: 35px;
    bottom: 5px;
    z-index: 99;
  }

  .DownloadIcon1 {
    position: absolute;
    left: 5px;
    bottom: 5px;
    z-index: 99;
  }

  .NoFileDiv {
    height: 200px;
    border: 1px solid #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    p {
      width: 67%;
    }
  }
}

.AddArtform {
  .imageDiv {
    position: relative;
    width: 200px;
    height: 200px;
    background: #f5f5f5;
    border: 0.5px dashed #c6c6c6;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      border: 1px solid #decdcd;
      border-radius: 5px;
    }

    svg {
      width: 100%;
      cursor: pointer;
    }

    .clickText {
      position: relative;
      left: 25px;
    }
  }
}

.hideMenuBarMobile {
  display: flex;
}

.QuickFiltersHeader,
.QuickFiltersHeader1 {
  display: flex;
}

.mobileToggle {
  display: none;
}

// @media (min-width: 1024px) {
//   .imageDiv1 {
//     img {
//       width: 200px !important;
//     }
//     iframe,
//     video {
//       width: 200px !important;
//     }
//   }
// }
@media (min-width: 641px) and (max-width: 800px) {
  .QuickFiltersHeader1 {
    display: grid;
    grid-template-columns: 1fr 1fr;

    div {
      margin-right: 5px;
    }
  }
}

@media (min-width: 801px) and (max-width: 1300px) {
  .QuickFiltersHeader1 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    div {
      margin-right: 5px;
    }
  }
}

// filters
@media (max-width: 640px) {

  .sideArrow,
  .sideArrow1 {
    display: none;
  }

  #my-sidebar-mobile {
    display: block !important;
    z-index: 1000;
  }

  .QuickFiltersHeader,
  .QuickFiltersHeader1 {
    display: block;
    padding-right: 30px !important;

    .addIcon,
    .searchIcon {
      display: none;
    }

    .InputColP2 {
      margin: 20px 8px;
      width: 100%;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
    }

    .InputDiv {
      height: 40px;
    }
  }
}

// table responsive
// @media (min-width: 1225px) and (max-width: 1600px) {
//   .tableParentProduct {
//     overflow: auto;

//     .tableHeaderRow,
//     .tableBodyDeatils {
//       width: 120%;
//       // font-size: 12px;
//     }
//   }
// }

@media (min-width: 900px) and (max-width: 1224px) {

  .tableParent .tableParentDashboard {
    overflow: auto;

    .tableHeaderRow,
    .tableBodyDeatils {
      width: 150%;
      // font-size: 12px;
    }
  }

  .tableParent1 {
    width: auto;

    .tableBodyDeatils,
    .tableHeaderRow {
      width: 307%;
    }
  }
}

@media (min-width: 640px) and (max-width: 899px) {

  .tableParent,
  .tableParent1 .tableParentDashboard {
    overflow: auto;

    .tableHeaderRow,
    .tableBodyDeatils {
      width: 240%;
    }
  }

  .tableParent1 {
    overflow: auto;

    .tableHeaderRow,
    .tableBodyDeatils {
      width: 350%;
    }
  }
}

@media (min-width: 380px) and (max-width: 639px) {

  .tableParent {
    overflow: auto;

    .tableHeaderRow,
    .tableBodyDeatils {
      width: 250%;
      font-size: 14px;
    }

    .tableBodyDeatils {
      .IndexDatatableNeoCol {
        font-size: 14px;

        .ProductFlowStatusParent {
          .hr {
            top: 6px;
          }

          .ProductFlowStatus {

            .ps1,
            .ps2,
            .ps3,
            .ps4,
            .ps5 {
              width: 15px;
              height: 15px;

              .innerCircle {
                width: 7px;
                height: 7px;
                margin-top: 4px;
              }
            }
          }
        }
      }
    }
  }

  .tableParent1 {
    overflow: auto;

    .tableHeaderRow,
    .tableBodyDeatils {
      width: 300%;
      font-size: 14px;
    }

    .tableBodyDeatils {
      .IndexDatatableNeoCol {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 379px) {

  .tableParent,
  .tableParent1 {
    overflow: auto;

    .tableHeaderRow,
    .tableBodyDeatils {
      width: 400%;
      font-size: 14px;
    }

    .tableBodyDeatils {
      .IndexDatatableNeoCol {
        font-size: 14px;
      }
    }
  }

  .tableParent1 {
    overflow: auto;

    .tableHeaderRow,
    .tableBodyDeatils {
      width: 500%;
      font-size: 14px;
    }

    .tableBodyDeatils {
      .IndexDatatableNeoCol {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 640px) {
  .ArryyIcon {
    .showOrHide {
      left: 22px;
      bottom: 13px;
    }

    .DownloadIcon {
      left: 54px;
      bottom: 13px;
    }

    .ViewPin {
      left: 84px !important;
      bottom: 13px;
    }
  }

  .ArryyIcon {
    .close {
      right: -41%;
    }
  }

  .closeIconStyle {
    position: absolute;
    top: 13px;
    right: 7px;
    z-index: 999;
    cursor: pointer;
  }

  #contentCard {
    margin-left: 0px !important;
  }

  #mySidebar {
    display: none;
  }

  .mobileToggle {
    display: block !important;
  }

  .hideMenuBarMobile {
    display: none !important;
  }

  .dropdown {
    button {
      font-size: 12px;
      width: 180px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  // Modals
  .TransferModal,
  .QRModal,
  .ArtFormModal,
  .TypeModal,
  .CategoryModal,
  .SubCategoryModal,
  .WeaveTypeModal,
  .CreateUserModal {
    .modal-content {
      padding: 20px;
    }

    .Heading {
      svg {
        width: 13px;
      }
    }

    .AddArtform,
    .AddTypeForm,
    .AddCategoryForm,
    .AddSubCategoryForm,
    .AddWeaveTypeForm,
    .createUser {
      .form {
        label {
          font-size: 14px;
        }

        .formDiv {
          margin-top: 20px;
          margin-bottom: 20px;
        }

        .formDiv1 {
          margin-top: 20px;
        }

        .form-control {
          padding: 5px;
          cursor: pointer;
          font-size: 12px;
        }
      }

      .artButton {
        padding: 10px 20px;
        background: #1a1a1a;
        margin-top: 20px;
        width: 50%;
        height: 50px;
      }
    }
  }

  .AddArtform {
    .imageDiv {
      width: 200px;
      height: 200px;

      .clickText {
        font-size: 12px;
      }
    }
  }

  .createUser,
  .createProduct {
    height: 800px;
    overflow-y: auto;

    .imageDiv {
      .clickText {
        font-size: 12px;
      }
    }

    .imageDiv1 {
      display: block;
      justify-content: center;
      height: auto;

      .PreviewDivPart {
        display: block;
        margin-top: 35px !important;
        margin: auto;
      }

      img,
      video,
      iframe {
        width: 200px !important;
        padding: 5px 15px;
      }

      .ArryyIcon {
        margin: 10px;
      }

      .divAddIcon {
        width: 30px;
        position: absolute;
        right: 12px;
        z-index: 999;
      }

      svg {
        // width: 100%;
        cursor: pointer;
        right: 75px;
      }

      .clickText {
        font-size: 12px;
      }
    }

    .UserHeader {
      font-size: 16px;
      padding-top: 21px;
      padding-bottom: 20px;
    }

    .icons {
      height: 113px;

      svg {
        width: 50px;
      }
    }

    .userPd {
      padding: 20px;
    }

    .form {
      label {
        font-size: 14px;
      }

      .formDiv {
        margin-top: 20px;
        margin-bottom: 20px;

        .col-12 {
          margin-top: 15px;
        }
      }

      .formDiv1 {
        margin-top: 20px;
      }

      .form-control {
        padding: 10px;
        height: 40px;
        cursor: pointer;
      }

      .React_selector {
        input {
          height: 30px;
          cursor: pointer;
        }
      }
    }

    .react-select__control {
      background: #fff !important;
    }
  }

  .viewProductQR {
    display: block;
  }
}

// sidebar card
.mainExpandedStyle {
  position: relative;
  background-color: #404b3b;
  color: white;
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  transition: 0.5s;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 60px;
  white-space: nowrap;
  // border: 1px solid transparent;
}

.mainExpandedStyles {
  position: relative;
  background-color: #404b3b;
  color: white;
  height: 100%;
  width: 82px;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  max-width: 82px;
  transition: 0.5s;
  overflow-x: hidden;
  padding-top: 60px;
  white-space: nowrap;
  overflow-y: hidden;
}

@media (min-width: 1024px) and (max-width: 1501px) {
  .rs-anim-fade.rs-anim-in {
    left: 50% !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .rs-anim-fade.rs-anim-in {
    left: 30% !important;
  }
}

@media (min-width: 640px) and (max-width: 767px) {
  .rs-anim-fade.rs-anim-in {
    left: 17% !important;
  }
}

@media (max-width: 640px) {
  .rs-anim-fade.rs-anim-in {
    left: 2% !important;

    @media (min-width: 640px) and (max-width: 767px) {
      .rs-anim-fade.rs-anim-in {
        left: 17% !important;
        top: 323px !important;
      }
    }
  }
}

.ViewModal {
  .modal-body {
    .ViewDiv {
      border: 0.5px solid #d9d9d9;
      padding: 20px;
      background-color: #f5f5f5;
    }

    .row {
      padding: 10px;

      span {
        text-transform: capitalize;

        font-family: "Inria Serif";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */

        color: #1a1a1a;
      }

      .spanH1 {
        font-weight: 700 !important;
      }
    }
  }
}

// email verfication
// .EMailVerifyModal{

.EmailVerifcationPage {
  // background: url("../src//assets/images/Login banner image.png");
  // background-repeat: no-repeat;
  // background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .form {
    width: 100%;
    // height: 600px;
    // background-color: #1a1a1a;
    border-radius: 15px;

    img {
      padding: 20px;
      margin-top: 20px;
    }

    .formDiv {
      padding: 20px 0px;

      .form-control {
        height: 50px;
      }

      .sendBtn {
        padding: 10px 30px;
        float: right;
        background-color: #404b3b;
        margin-bottom: 30px;
        width: 100%;
      }

      .sendBtn1 {
        padding: 10px 30px;
        float: right;
        background-color: #c59c6c;
        margin-bottom: 30px;
        width: 100%;
        margin-left: 10px;
      }
    }
  }
}

@media (max-width: 480px) {
  .paginationDiv {
    span {
      font-size: 12px;
    }
  }

  .EmailVerifcationPage {
    // background: url("../src//assets/images/Login banner imageMobile.png");
    // background-repeat: no-repeat;
    // background-size: cover;

    .form {
      width: 100%;
      // height: 600px;
      // background-color: #1a1a1a;
      border-radius: 15px;

      img {
        padding: 10px;
        margin-top: 10px;
      }

      .formDiv {

        // padding: 10px 20px;
        .form-control {
          height: 40px;
        }

        .sendBtn {
          padding: 5px 15px;
          margin-bottom: 30px;
          font-size: 12px;
          height: 40px;
        }

        .sendBtn1 {
          padding: 5px 15px;
          font-size: 12px;
          height: 40px;
        }
      }
    }
  }
}

// @media (min-width: 481px) and (max-width: 768px) {
//   .EmailVerifcationPage {
//     background: url("../src//assets/images/Login banner imageMobile.png");
//     background-repeat: no-repeat;
//     background-size: cover;

//     .form {
//       width: 70%;
//       // height: 600px;
//       background-color: #1a1a1a;
//       border-radius: 15px;
//       img {
//         padding: 10px;
//         margin-top: 10px;
//       }
//       .formDiv {
//         padding: 10px 30px;
//         .form-control {
//           height: 40px;
//         }
//         .sendBtn {
//           padding: 5px 20px;
//           float: right;
//           margin-bottom: 30px;
//         }
//       }
//     }
//   }
// }
@media (min-width: 769px) and (max-width: 900px) {
  .EmailVerifcationPage {
    .form {
      width: 100%;
    }
  }
}

@media (min-width: 901px) and (max-width: 1024px) {
  .EmailVerifcationPage {
    .form {
      width: 6 0%;
    }
  }
}

//profile section
.profileSection {
  .userPd1 {
    display: flex;
    padding: 20px;

    .d1 {
      padding: 10px;
    }

    .d2 {
      padding: 10px;
      width: 100%;
    }
  }

  .updateProfileButton {
    padding: 10px 20px;
    background: #1a1a1a;
    margin: 20px;
    width: 200px;
    height: 50px;
    float: right;
  }
}

.viewUserProfile {
  display: flex;
  justify-content: flex-start;
  // height: 100%;
  // min-height: 100%;
  font-family: "Inria Serif";
  font-style: normal;

  .editprofileBtn {
    background: #1a1a1a;
    border-radius: 4px;
    width: 200px;
    padding: 10px 5px;
  }

  .d1 {
    padding: 30px;

    // width: 100%;
    .imgDiv {
      width: 250px;
      height: 250px;
      border-radius: 50%;
      background-color: #d9d9d9;
      border: 0.5px solid #424040;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        cursor: pointer;
      }

      .imageRound {
        border-radius: 50%;
      }
    }
  }

  .d2 {
    width: 100%;
    padding: 35px;

    h2 {
      font-weight: 400;
      font-size: 30px;
      line-height: 36px;
      color: #8c673e;
    }

    .d21 {
      // font-family: "Inria Serif";
      // font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      color: #404b3b;
      margin-top: 10px;
      display: flex;

      .sp1 {
        font-family: "Inria Serif";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #1a1a1a;
        margin-left: 15px;
        position: relative;
        top: 13px;
      }
    }

    .OrgDiv {
      display: flex;
      justify-content: space-between;
      padding: 10px 64px 15px 0px;
      border-bottom: 0.5px solid #d9d9d9;

      .OD1 {
        font-family: "Inria Serif";
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 30px;
        color: #1a1a1a;
        padding-top: 10px;
      }
    }

    .OrgSub {
      margin-top: 30px;
      margin-bottom: 30px;

      .OrD1 {
        display: flex;
        font-family: "Inria Serif";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #1a1a1a;
        padding-top: 20px;

        .OrD11 {
          width: 200px;
        }
      }
    }
  }
}

@media (min-width: 890px) and (max-width: 1300px) {
  .viewUserProfile {
    .OrD1 {
      div {
        width: 60%;
      }
    }
  }
}

@media (min-width: 640px) and (max-width: 889px) {
  .viewUserProfile {
    display: block;

    .d1 {
      display: flex;
      justify-content: center;

      .imgDiv {
        width: 350px;
        height: 350px;
      }
    }

    .OrD1 {
      div {
        width: 60%;
      }
    }
  }
}

@media (min-width: 480px) and (max-width: 639px) {
  .viewUserProfile {
    display: block;

    .d1 {
      display: flex;
      justify-content: center;

      .imgDiv {
        width: 250;
        height: 250px !important;
      }
    }

    .d2 {
      h2 {
        font-size: 20px;
      }

      .d21 {
        svg {
          width: 25px;
        }

        .sp1 {
          font-size: 14px;
        }
      }

      .OrD1 {
        div {
          width: 60%;
          font-size: 14px;
        }

        .OrD11 {
          font-size: 14px;
        }
      }

      .OrgDiv {
        padding: 10px 0px 15px 0px;
        margin-top: 20px;

        .OD1 {
          font-size: 16px;
        }

        svg {
          width: 25px;
        }
      }
    }
  }
}

@media (max-width: 479px) {
  .viewUserProfile {
    display: block;

    .d1 {
      display: flex;
      justify-content: center;

      .imgDiv {
        width: 200px;
        height: 200px !important;
      }
    }

    .d2 {
      h2 {
        font-size: 20px;
      }

      .d21 {
        svg {
          width: 25px;
        }

        .sp1 {
          font-size: 14px;
        }
      }

      .OrD1 {
        div {
          width: 50%;
          font-size: 14px;
        }

        .OrD11 {
          font-size: 14px;
          width: 35%;
        }
      }

      .OrgDiv {
        margin-top: 20px;
        padding: 10px 0px 15px 0px;

        .OD1 {
          font-size: 16px;
        }

        svg {
          width: 25px;
        }
      }
    }
  }
}

// @media (min-width:1000px) and (max-width:1300px){
//   .viewUserProfile{
//     .OrD1{
//       div{
//         width: 60%;
//       }
//     }
//   }

// }
@media (max-width: 1000px) {
  .profileSection {
    .userPd1 {
      display: block;

      .d1 {
        display: flex;
        justify-content: center;
      }

      .d2 {
        .row {

          .col-12,
          .col-md-4 {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 1560px) {
  .ArryyIcon {
    .close {
      right: 30px;
    }
  }
}

.createProduct {

  // .form-control,
  // .react-select__control{
  //   border: 0.5px solid #484848 !important;
  //   background-color: #fff !important;
  // }
  .React_selector,
  .react-select__control {
    background-color: #fff !important;
  }

  .ReactSelectDiv {
    .react-select__indicator-separator {
      background: none !important;
    }

    .react-select__indicator {
      color: #1a1a1a;
    }
  }
}

.react-select__option--is-selected {
  background-color: #c59c6c !important;
}

.react-select__option--is-focused {
  background-color: #ead6c0 !important;
}

.partyModal,
.changePswd {
  .react-select__control {
    min-height: 50px;
  }
}

.changePswd,
.resetPswd {
  .formDiv {
    position: relative;

    .icon {
      position: absolute;
      right: 12px;
      top: 56px !important;
      cursor: pointer;
    }
  }

  .resend {
    float: right;
    color: #740b15;
    cursor: pointer;
    padding: 5px;
  }
}

.changePswd,
.resetPswd {
  .icon {
    position: absolute;
    right: 12px;
    top: 47px;
    cursor: pointer;
  }

  .formDiv {
    position: relative;
  }
}

.CreateProductDetails,
.UserDetailsDiv,
.partyModal,
.changePswd,
.resetPswd {
  .formDiv {
    .formDivSub {
      display: flex;

      .DivSep {
        height: 30px;
        border-left: 1px solid hsl(0, 0%, 80%);
        margin: 10px 0px;
      }

      .React_selector {
        width: 100%;

        select ::selection {
          background-color: #000 !important;
        }

        .react-select__control {
          border-right: none !important;
          border: 0.5px solid #d9d9d9;
          background-color: #fff !important;
          border-radius: 4px 0px 0px 4px;
        }
      }

      .formAddDiv {
        width: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.5px solid #d9d9d9;
        border-left: none;
        margin-left: -3px;
        border-radius: 0px 4px 4px 0px;
      }
    }
  }
}

@media (max-width: 640px) {

  .CreateProductDetails,
  .UserDetailsDiv {
    .formDiv {
      .formAddDiv {
        height: 44px;
      }

      .DivSep {
        height: 24px !important;
      }
    }
  }
}

.LangDiv {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;

  .d1,
  .divText,
  .formDivSub {
    width: 100%;
    font-family: Roboto;
    font-size: 16px;
  }

  .divText {
    margin-top: 5px;
    font-weight: 700;
  }
}

@media (max-width: 768px) {
  .LangDiv {
    display: block;
  }
}

.heightPro {
  height: 490px;
  overflow: auto;
}

@media (max-width: 768px) {
  .heightPro {
    height: auto;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.modal {
  overflow-y: auto !important;
  overflow-x: hidden;
}

.icon {
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
}

.resetForProduct {
  margin-top: 33px;
  background-color: #404b3b;
  color: #fff;
  height: 50px;
  float: left;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 10px;
}

.mobileReset {
  display: none;
}

.DesktopReset {
  display: block;
}

@media (max-width: 640px) {
  .resetForProduct {
    height: 40px;
    margin-top: 30px;
    float: right;
    padding-left: 10px;
    padding-right: 10px;
  }

  .mobileReset {
    display: block;
  }

  .DesktopReset {
    display: none;
  }
}

// failed pages
.SomeWentCard {
  background-color: hsl(0, 12%, 94%);

  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .SomethingWentWrong {
    font-family: Roboto;
    font-size: 18px;
    border: 0.2px solid #424040;
    border-radius: 8px;
    padding: 9px 38px;
  }
}

// dropdown
.actionDropdown {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .dropdown button {
    font-size: 12px;
    width: unset !important;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .dropdown {
    .dropdown-item {
      width: 100% !important;
      text-align: center;
      font-size: 14px;
      font-family: "inter";
      font-weight: 500;
    }
  }

  .d1 {
    // padding-right: 5px;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: #fff !important;
    text-decoration: none;
    background-color: #404b3b;
    padding-top: 5px;
    padding-bottom: 5px;

    span {
      color: #fff !important;
    }
  }
}

.text-Uppercase {
  text-transform: uppercase !important;
}

.alignmentForProfile {
  border: 1px solid #e9dbdb;
  border-radius: 5px;
  margin: 20px 20px;

  .editIcon {
    width: 40px;
    height: 40px;
    // padding-right: 10px;
    margin-right: 5px;
    cursor: pointer;
  }
}

.tooltipIcon {
  position: relative;
  display: inline-block;
}

.tooltipIcon .tooltipIcontext {
  visibility: hidden;
  width: 200px;
  min-width: fit-content;
  white-space: wrap;
  background-color: #0d1b2b;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 999;
  bottom: 150%;
  left: -86px;
}

.tooltipIcon .tooltipIcontext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #05284e transparent transparent transparent;
}

.tooltipIcon:hover .tooltipIcontext {
  visibility: visible;
}